<template>
  <div class="topbar-fixed-container">
    <div class="topbar-main flex-col">
      <div class="topbar-general flex-row">
        <a href="https://info.geode.fi" target="_blank" class="logo">
          <img class="geode-logo" src="/assets/logo_top.svg" />
        </a>
        <Socials class="desktopSocials" iconSize="18px" iconHeight="16px" />
        <transition name="zoominreverse">
          <menuButton @click="Clicked" :opened="navbar_opened" />
        </transition>
      </div>
      <transition name="slide-fade">
        <div v-if="navbar_opened">
          <Socials class="socials" align="center" iconSize="24px" iconHeight="18px" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue"
import menuButton from "./menuButton.vue"
import Socials from "../socials/index.vue"

const navbar_opened = ref(false)

function Clicked(event) {
  navbar_opened.value = !navbar_opened.value
}
</script>

<style scoped>
.topbar-fixed-container {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: var(--z-topbar);
}

.topbar-main {
  position: relative;
  width: 100%;
  padding: 0 4%;
  margin: auto;
  justify-content: center;
  background: linear-gradient(#000, #000000bb, #00000099, transparent);
  align-items: stretch;
}

.button-container {
  width: 100%;
  align-self: stretch;
  white-space: nowrap;
}

.geode-logo {
  height: 20px;
}

.logo {
  position: relative;
}

.topbar-general {
  justify-content: space-between;
  height: 80px;
}

.socials {
  margin: auto;
  margin-bottom: 18px;
}

/*  slide-fade anim */
.slide-fade-enter-active {
  transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-in;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

@media screen and (max-width: 600px) {
  .geode-logo {
    height: 22px;
    margin-top: 4px;
  }

  .desktopSocials {
    display: none;
  }
}
</style>
