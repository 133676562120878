import { createApp } from "vue"
import "@/style.css"

// App
import App from "./App.vue"
const app = createApp(App)

// Components
import loader from "@/components/utils/Loader.vue"
import loadingSvg from "@/components/utils/Loading.svg.vue"
import buttonVue from "@/components/utils/Button.vue"
import imageVue from "@/components/utils/Image.vue"
import noteBar from "@/components/utils/NoteBar.vue"
import topBar from "@/components/TopBar/index.vue"

app.component("top-bar", topBar)
app.component("note-bar", noteBar)
app.component("loader", loader)
app.component("loading-svg", loadingSvg)
app.component("button-vue", buttonVue)
app.component("image-vue", imageVue)

// Progress Bar
import VueProgressBar from "@aacassandra/vue3-progressbar"
const VueProgressBarOpts = {
  color: "#8761ff",
  failedColor: "#c91d3c",
  thickness: "3px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  autoFinish: true,
  location: "top",
  inverse: false,
}
app.use(VueProgressBar, VueProgressBarOpts)

// router
import router from "./router"
app.use(router)

// Store
import { createPinia } from "pinia"
const pinia = createPinia()
app.use(pinia)

// Toast
import Toast from "vue-toastification"
import { useToast } from "vue-toastification"
import "vue-toastification/dist/index.css"
app.use(Toast, {
  transition: "Vue-Toastification__fade",
  position: "bottom-right",
  maxToasts: 10,
  timeout: 3000,
  newestOnTop: true,
  closeOnClick: false,
  showCloseButtonOnHover: true,
  pauseOnHover: true,
  pauseOnFocusLoss: true,
  draggable: true,
  draggablePercent: 0.25,
})

// error handler
app.config.errorHandler = function (err) {
  // handle error
  // `info` is a Vue-specific error info, e.g. which lifecycle hook
  // the error was found in. Only available in 2.2.0+
  try {
    const toast = useToast()
    toast.error(`Error: something went wrong :(`)
    console.log(err)
  } catch (err) {
    console.log(err)
  }
}

app.mount("#app")

export default app
