<template>
  <vue-progress-bar />
  <Transition name="loader" mode="out-in">
    <loader v-if="!domIsReady" />
  </Transition>
  <router-view />
</template>

<script setup>
import { onMounted, ref } from "vue"
import timeout from "@/common/timeout"

var domIsReady = ref(false)

onMounted(() => {
  document.onreadystatechange = async () => {
    if (document.readyState == "complete") {
      await timeout(1000)
      domIsReady.value = true
    }
  }
})
</script>

<style>
#app,
.container {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

#app {
  min-height: 100vh;
  min-width: 100vw;
}
</style>
