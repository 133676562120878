<template>
  <a rel="noopener noreferrer" target="_blank" :href="href" hreflang="en">
    <image-vue :src="'socials/' + src + '.svg'" :width="size" :height="height" :padding="padding" />
  </a>
</template>

<script>
export default {
  props: ["href", "src", "size", "height", "background", "padding"],
}
</script>

<style scoped></style>
