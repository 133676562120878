<template>
  <Transition name="descend" appear mode="out-in">
    <div :class="['notewrapper', 'flex-row', type ? type : 'normal']" v-show="!closed">
      <div class="xxxsmall msg bold">{{ text }}</div>
      <image-vue src="icons/x.svg" height="16px" class="closer hoverable" @click="close" />
    </div>
  </Transition>
</template>

<script setup>
import { ref, onMounted } from "vue"
import timeout from "@/common/timeout"

const props = defineProps(["type", "text", "autoclose"])

const closed = ref(false)

onMounted(async () => {
  if (props.autoclose) {
    await timeout(5000)
    close()
  }
})

const close = () => {
  closed.value = true
}
</script>

<style scoped>
.notewrapper {
  position: fixed;
  top: 0;
  width: 100vw;
  margin: 0;
  padding: 0;
  justify-content: space-between;
  z-index: 100;
  z-index: 1000;
}
.msg {
  padding-left: var(--norm-pad);
  padding-right: var(--norm-pad);
  min-height: var(--gap-height);
  text-align: center;
  line-height: var(--gap-height);
  width: 100%;
}
.closer {
  opacity: 75%;
  padding: 0 var(--norm-pad);
  filter: invert();
}
.normal {
  background: var(--color-purple);
}
.info {
  background: var(--color-blue);
}
.success {
  background: var(--color-green);
}
</style>
