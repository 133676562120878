<template>
  <div class="ButtonMain" @click="buttonClick" style="transform-origin: 50% 50% 0px">
    <div :class="{ line1: true, rotate: props.opened }"></div>
    <div :class="{ line2: true, remove: props.opened }"></div>
    <div :class="{ line3: true, rotateReverse: props.opened }"></div>
  </div>
</template>

<script setup>
const emit = defineEmits(["click"])
const props = defineProps(["opened"])

function buttonClick() {
  emit("click")
}
</script>

<style scoped>
.ButtonMain {
  display: none;
  position: relative;
  width: 32px;
  height: 32px;
  aspect-ratio: 1 / 1;
  flex: 0 0 auto;
}

.line1 {
  background-color: rgb(153, 153, 153);
  border-radius: 10px;
  transition: all 1s ease;
  transform-origin: 50% 50% 0px;
  bottom: 7px;
  flex: 0 0 auto;
  height: 2px;
  left: calc(50% - 12px);
  overflow: hidden;
  position: absolute;
  width: 24px;
  will-change: transform;
}

.line2 {
  background-color: rgb(153, 153, 153);
  border-radius: 10px;
  transition: all 1s ease;
  transform-origin: 50% 50% 0px;
  height: 2px;
  left: calc(50% - 12px);
  overflow: hidden;
  position: absolute;
  top: calc(50% - 1px);
  width: 24px;
  will-change: transform;
}

.line3 {
  background-color: rgb(153, 153, 153);
  border-radius: 10px;
  transition: all 1s ease;
  transform-origin: 50% 50% 0px;
  flex: 0 0 auto;
  height: 2px;
  left: calc(50% - 12px);
  overflow: hidden;
  position: absolute;
  top: 7px;
  width: 24px;
  will-change: transform;
}

.remove {
  opacity: 0;
}

.rotate {
  transform: translateY(-8px) rotate(45deg);
}

.rotateReverse {
  transform: translateY(8px) rotate(-45deg);
}

@media screen and (max-width: 600px) {
  .ButtonMain {
    display: block;
  }
}
</style>
