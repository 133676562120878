<template>
  <div class="SocialMain">
    <div class="Socials flex-row" :style="{ justifyContent: align }">
      <Social
        v-for="l in networks"
        :key="l.href"
        :href="l.href"
        :src="l.img"
        :size="iconSize"
        :height="iconHeight ?? '18px'"
        class="single"
      ></Social>
    </div>
  </div>
</template>

<script>
import Social from "./Social.vue"

export default {
  props: ["iconSize", "iconHeight", "align"],
  data: () => ({
    networks: [
      {
        href: "https://docs.geode.fi/",
        img: "docs",
      },
      {
        href: "https://medium.com/geode-finance",
        img: "medium",
      },
      {
        href: "https://discord.gg/RC8fTTuJtm",
        img: "discord",
      },
      {
        href: "https://twitter.com/geode_finance",
        img: "twitter",
      },
    ],
  }),
  components: { Social },
}
</script>

<style scoped>
.Socials {
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  gap: 25px;
}

.single {
  border-radius: 12px;
  /* margin: var(--mini-pad); */
}
</style>
